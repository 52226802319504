import React from "react";
import { Provider } from "react-redux";
import { createStore as reduxCreateStore, applyMiddleware, compose } from "redux";
import rootReducer from ".";
import thunk from "redux-thunk";
import debounce from "lodash.debounce";

const persistenceWhitelist = [
	"user",
	"options"
];
const persistenceVersion = 2;

const createStore = () => {

	const localStorageState = (typeof localStorage !== "undefined" && localStorage.getItem("reduxState")) ? JSON.parse(localStorage.getItem("reduxState")) : {};
	const persistedState = localStorageState.version >= persistenceVersion ? localStorageState.state : {};

	const composeEnhancers =
		typeof window === "object" &&
		window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
			window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
				// Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
			}) : compose;

	const store = reduxCreateStore(
		rootReducer,
		persistedState,
		composeEnhancers(applyMiddleware(thunk)),
	);

	store.subscribe(debounce(() => {
		const storeSnap = store.getState();
		const filteredStore = filterStore(persistenceWhitelist, storeSnap);

		localStorage.setItem("reduxState", JSON.stringify({
			version: persistenceVersion,
			state: { ...filteredStore, options: { ...filteredStore.options, newFilename: "", resizeHeight: false, resizeSize: 0,  } },
		}));
	}, 1000));

	return store;
};

export default ({ element }) => (
	<Provider store={ createStore() }>{ element }</Provider>
);

export const objectfilterStore = (whitelist, store) => Object.keys(whitelist).reduce((prev, cur) => {
	const value = whitelist[cur];
	return { ...prev, [cur]: typeof value === "object" ? filterStore(value, store[cur]) : store[cur] };
}, {});

export const filterStore = (whitelist, store) => whitelist.reduce((prev, cur) => {
	if (typeof cur === "string") {
		return { ...prev, [cur]: store[cur] };
	}
	return prev;
}, {});
