export const COMPRESSION_UPLOADING = "COMPRESSION_UPLOADING";
export const COMPRESSION_PROCESSING = "COMPRESSION_PROCESSING";
export const COMPRESSION_RECEIVING = "COMPRESSION_RECEIVING";
export const COMPRESSION_DONE = "COMPRESSION_DONE";
export const COMPRESSION_ERROR = "COMPRESSION_ERROR";

export const ZIP_PREPARING = "ZIP_PREPARING";
export const ZIP_UPLOADING = "ZIP_UPLOADING";
export const ZIP_DONE = "ZIP_DONE";
export const ZIP_ERROR_UNZIP = "ZIP_ERROR_UNZIP";
export const ZIP_ERROR_COMPRESSION = "ZIP_ERROR_COMPRESSION";