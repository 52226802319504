module.exports = [{
      plugin: require('/home/tinify/nanopng-web/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/tinify/nanopng-web/node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://007de64954ec4ebf9d7af95764e7a016@sentry.io/2677101","environment":"production","enabled":true},
    },{
      plugin: require('/home/tinify/nanopng-web/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
