import { addFileSubfix, getFileMIME, getDownscaleMIME, getOptionsFormData, renameFile, isAuthed } from "../utils/Misc";
import { produce } from "immer";
import axios from "axios";
import * as CompressionStatus from "../utils/CompressionStatus";
import JSZip from "jszip";
import uuidv4 from "uuid/v4";
import * as downscale from "downscale";

const initialState = {
	url: "",
	urlInvalid: false,
	images: {},
	zips: {
		/*
		"id": {
			name: "",
			images: {
				"id": {
					relativePath: ""
				}
			},
			timestamp: 123,
			error: null,
		}
		 */
	},
};

const COMPRESSION_PROGRESS_UPLOAD = "COMPRESSION_PROGRESS_UPLOAD";
const COMPRESSION_PROGRESS_DOWNLOAD = "COMPRESSION_PROGRESS_DOWNLOAD";
const COMPRESSION_DONE = "COMPRESSION_DONE";
const COMPRESSION_ERROR = "COMPRESSION_ERROR";

const FILE_PENDING = "FILE_PENDING";
export const compressFile = (file, { zip = false, customID = false }) => async (dispatch, getState) => {
	const id = customID || uuidv4();
	const options = getState().options;
	const token = getState().user.token;

	const downscaleMIME = getDownscaleMIME(file.name);
	let downscaledImage;
	if (options.resizeSize !== 0 && downscaleMIME != null) {
		downscaledImage = new File([await downscale(file, options.resizeHeight ? 0 : options.resizeSize, options.resizeHeight ? options.resizeSize : 0, {
			imageType: downscaleMIME,
			quality: 1,
			returnBlob: true,
		})], file.name, { type: file.type });
	}

	dispatch({ type: FILE_PENDING, file, id, zip });
	const imagesState = getState().files.images;
	const imageIndex = Object.keys(imagesState).map(key => imagesState[key]).filter(image => !image.zip).length;

	const formData = getOptionsFormData(options);
	formData.append("file", downscaledImage || file);

	try {
		const res = await axios.post(`${ process.env.GATSBY_API_BASE_URL }/api/images/compress${ isAuthed(token) ? "/authed" : "" }`, formData, {
			responseType: "blob",
			onUploadProgress: ({ loaded, total }) => dispatch({
				type: COMPRESSION_PROGRESS_UPLOAD,
				percent: (loaded / total * 100).toFixed(2),
				id,
			}),
			onDownloadProgress: ({ loaded, total }) => dispatch({
				type: COMPRESSION_PROGRESS_DOWNLOAD,
				percent: (loaded / total * 100).toFixed(2),
				id,
			}),
			headers: isAuthed(token) ? { "Authorization": `Bearer ${token}` } : undefined,
		});

		const type = res.headers["content-type"];
		const compressedFile = new File([res.data],
			(options.newFilename && !zip)
				? renameFile(file.name, options.newFilename, options.addEnumeration ? imageIndex : null)
				: ((options.addSubfix && !zip)
					? addFileSubfix(file.name, "-tinified")
					: file.name
				), { type });
		dispatch({ type: COMPRESSION_DONE, compressedFile, id });
	} catch (err) {

		console.log(err);
		dispatch({ type: COMPRESSION_ERROR, err, id });
	}
};

const UPDATE_URL = "UPDATE_URL";
export const updateURL = value => ({
	type: UPDATE_URL,
	value,
});

const UPDATE_URLINVALID = "UPDATE_URLINVALID";
export const updateURLInvalid = value => ({
	type: UPDATE_URLINVALID,
	value,
});

const URL_PENDING = "URL_PENDING";
export const compressURL = () => (dispatch, getState) => {
	const id = uuidv4();
	const options = getState().options;
	const token = getState().user.token;
	const url = getState().files.url;
	dispatch({ type: URL_PENDING, id });
	const imagesState = getState().files.images;
	const imageIndex = Object.keys(imagesState).map(key => imagesState[key]).filter(image => !image.zip).length;

	const formData = getOptionsFormData(options);
	formData.append("url", url);

	axios.post(`${ process.env.GATSBY_API_BASE_URL }/api/images/compress${ isAuthed(token) ? "/authed" : "" }`, formData, {
		responseType: "blob",
		onDownloadProgress: ({ loaded, total }) => dispatch({
			type: COMPRESSION_PROGRESS_DOWNLOAD,
			percent: (loaded / total * 100).toFixed(2),
			id,
		}),
		headers: isAuthed(token) ? { "Authorization": `Bearer ${token}` } : undefined,
	})
		.then(res => {
			const type = res.headers["content-type"];

			let urlFilename = url.substring(url.lastIndexOf("/") + 1);
			if (options.newFilename) {
				urlFilename = renameFile(urlFilename, options.newFilename, imageIndex);
			} else if (options.addSubfix) {
				urlFilename = addFileSubfix(urlFilename, "-tinified");
			}

			const compressedFile = new File([res.data], urlFilename, { type });
			dispatch({ type: COMPRESSION_DONE, compressedFile, id });
		})
		.catch(error => {
			console.log(error);
			dispatch({ type: COMPRESSION_ERROR, error, id });
		});
};

const DELETE_IMAGE = "DELETE_IMAGE";
export const deleteImage = key => ({
	type: DELETE_IMAGE,
	key,
});

const DELETE_ZIP = "DELETE_ZIP";
export const deleteZIP = key => ({
	type: DELETE_ZIP,
	key,
});

const ZIP_PREPARING = "ZIP_PREPARING";
const ZIP_ADD_IMAGE = "ZIP_ADD_IMAGE";
const ZIP_ERROR_UNZIP = "ZIP_ERROR_UNZIP";
const ZIP_ERROR_COMPRESSION = "ZIP_ERROR_COMPRESSION";
export const compressZIP = (zipFile) => (dispatch, getState) => {
	const id = uuidv4();

	dispatch({ type: ZIP_PREPARING, id, name: zipFile.name, sizeIn: zipFile.size });

	JSZip.loadAsync(zipFile)
		.then(zipEntries => {
			let zipPromises = [];
			zipEntries.forEach((relativePath, zipEntry) => {
				if (!zipEntry.dir) {
					zipPromises.push(new Promise((resolve, reject) => {
						zipEntry.async("blob")
							.then(blob => {
								resolve({ relativePath, blob });
							})
							.catch(reject);
					}));
				}
			});

			Promise.all(zipPromises)
				.then(res => res.forEach(zipImage => {
					const imageID = uuidv4();
					dispatch({ type: ZIP_ADD_IMAGE, id, imageID });
					dispatch(compressFile(new File([zipImage.blob], zipImage.relativePath, { type: getFileMIME(zipImage.relativePath) }), {
						customID: imageID,
						zip: id,
					}));
				}))
				.catch(error => {
					console.error(error);
					dispatch({ type: ZIP_ERROR_UNZIP, error });
				});
		})
		.catch(error => {
			console.error(error);
			dispatch({ type: ZIP_ERROR_UNZIP, error });
		});
};


export default (state = initialState, action) =>
	produce(state, draft => {
		switch (action.type) {
			case COMPRESSION_PROGRESS_UPLOAD:
				draft.images[action.id].progress.upload = action.percent;

				if (draft.images[action.id].zip) {
					draft.zips[draft.images[action.id].zip].status = CompressionStatus.ZIP_UPLOADING;
				}

				// eslint-disable-next-line
				if (action.percent == 100) {
					draft.images[action.id].status = CompressionStatus.COMPRESSION_PROCESSING;
				}
				break;
			case COMPRESSION_PROGRESS_DOWNLOAD:
				draft.images[action.id].progress.download = action.percent;
				draft.images[action.id].status = CompressionStatus.COMPRESSION_RECEIVING;
				break;
			case COMPRESSION_DONE:
				/*if (draft.images[action.id].file) {
					draft.images[action.id].file = {
						path: draft.images[action.id].file.path,
						name: draft.images[action.id].file.name,
						size: draft.images[action.id].file.size,
					};
				}*/
				draft.images[action.id].compressedFile = action.compressedFile;
				draft.images[action.id].status = CompressionStatus.COMPRESSION_DONE;
				if (draft.images[action.id].zip && draft.zips[draft.images[action.id].zip].images.filter(elem => draft.images[elem].status !== COMPRESSION_DONE).length === 0) {
					draft.zips[draft.images[action.id].zip].status = CompressionStatus.ZIP_DONE;
				}
				break;
			case COMPRESSION_ERROR:
				draft.images[action.id].error = action.error;
				draft.images[action.id].status = CompressionStatus.COMPRESSION_ERROR;
				if (draft.images[action.id].zip) {
					draft.zips[draft.images[action.id].zip].error = action.error;
					draft.zips[draft.images[action.id].zip].status = CompressionStatus.ZIP_ERROR_COMPRESSION;
				}
				break;

			case FILE_PENDING:
				draft.images[action.id] = {
					file: action.file,
					timestamp: +new Date(),
					progress: { upload: 0, download: 0 },
					compressedFile: null,
					error: null,
					status: CompressionStatus.COMPRESSION_UPLOADING,
					zip: action.zip,
				};
				break;

			case UPDATE_URL:
				draft.url = action.value;
				draft.urlInvalid = false;
				break;
			case UPDATE_URLINVALID:
				draft.urlInvalid = action.value;
				break;
			case URL_PENDING:
				draft.images[action.id] = {
					url: state.url,
					timestamp: +new Date(),
					progress: { upload: 0, download: 0 },
					compressedFile: null,
					error: null,
					status: CompressionStatus.COMPRESSION_PROCESSING,
				};
				draft.url = "";
				break;

			case ZIP_PREPARING:
				draft.zips[action.id] = {
					name: action.name,
					timestamp: +new Date(),
					images: [],
					status: CompressionStatus.ZIP_PREPARING,
					sizeIn: action.sizeIn,
					error: null,
				};
				break;
			case ZIP_ADD_IMAGE:
				draft.zips[action.id].images.push(action.imageID);
				draft.zips[action.id].status = CompressionStatus.ZIP_UPLOADING;
				break;
			case ZIP_ERROR_UNZIP:
				draft.zips[action.id].error = action.error;
				draft.zips[action.id].status = CompressionStatus.ZIP_ERROR_UNZIP;
				break;
			case ZIP_ERROR_COMPRESSION:
				draft.zips[action.id].error = action.error;
				draft.zips[action.id].status = CompressionStatus.ZIP_ERROR_COMPRESSION;
				break;

			case DELETE_IMAGE:
				if (draft.images[action.key].status === CompressionStatus.COMPRESSION_ERROR
					|| draft.images[action.key].status === CompressionStatus.COMPRESSION_DONE) {
					delete draft.images[action.key];
				}
				break;

			case DELETE_ZIP:
				if (draft.zips[action.key].images.map(imageID => {
					const imageStatus = draft.images[imageID].status;
					return imageStatus === CompressionStatus.COMPRESSION_DONE || imageStatus === CompressionStatus.COMPRESSION_ERROR;
				}).reduce((a, b) => a && b)) {
					draft.zips[action.key].images.forEach(imageID => delete draft.images[imageID]);
					delete draft.zips[action.key];
				}
				break;

			default:
				break;
		}
	});
