import { combineReducers } from "redux";
import options from "./options";
import files from "./files";
import user from "./user";

export default combineReducers({
	options,
	files,
	user,
});
