import { produce } from "immer";

const initialState = {
	stripMetadata: true,
	lossless: false,
	pOptLevel: 7, // min. 1, max. 10 (lossless: min. 1, max. 8)
	pQuality: 70, // min. 0, max. 100
	jQuality: 70, // min. 0, max. 100
	jProgressive: true,
	sRemoveScript: true,
	gInterlaced: false,
	gOptLevel: 2, // min. 1, max. 3
	gLossLevel: 80, // min. 1, max. 300, 30 -> light, 200 -> heavy
	newFilename: "",
	resizeHeight: false,
	resizeSize: 0,
	addSubfix: true,
	addEnumeration: false,
};


const UPDATE_SIMPLE_OPTION = "UPDATE_SIMPLE_OPTION";
export const updateSimpleOption = value => ({
	type: UPDATE_SIMPLE_OPTION,
	value,
});

const UPDATE_OPTION = "UPDATE_OPTION";
export const updateOption = (key, value) => ({
	type: UPDATE_OPTION,
	key,
	value,
});

const UPDATE_LOSSLESS = "UPDATE_LOSSLESS";
export const updateLossless = value => ({
	type: UPDATE_LOSSLESS,
	value,
});

const UPDATE_NEWFILENAME = "UPDATE_NEWFILENAME";
export const updateNewFilename = value => ({
	type: UPDATE_NEWFILENAME,
	value,
});


export default (state = initialState, action) =>
	produce(state, draft => {
		switch (action.type) {
			case UPDATE_SIMPLE_OPTION:
				draft.pQuality = action.value;
				draft.jQuality = action.value;
				break;
			case UPDATE_LOSSLESS:
				draft.lossless = action.value;
				draft.pOptLevel = state.pOptLevel > 8 ? 8 : state.pOptLevel;
				break;
			case UPDATE_OPTION:
				draft[action.key] = action.value;
				break;
			case UPDATE_NEWFILENAME:
				draft.newFilename = action.value;
				break;

			default:
				break;
		}
	});
