import { produce } from "immer";
import axios from "axios";
import { navigate } from "gatsby";

const initialState = {
	token: "",
	user: {
		firstName: "",
		lastName: "",
		email: "",
	},
	error: {
		login: "",
		fetch: "",
	},
};

const USER_LOGIN = "USER_LOGIN";
const USER_LOGIN_ERROR = "USER_LOGIN_ERROR";
export const loginUser = (email, password) => async (dispatch, getState) => {
	try {
		const res = await axios.post(`${ process.env.GATSBY_API_BASE_URL }/api/users/login`, {
			username: email,
			password,
		});
		dispatch({ type: USER_LOGIN, data: res.data });
		dispatch(fetchUser());
		navigate("/");
	} catch (err) {
		switch (err && err.response && err.response.status) {
			case 401:
				dispatch({ type: USER_LOGIN_ERROR, error: "Invalid username or password." });
				break;
			default:
				dispatch({ type: USER_LOGIN_ERROR, error: err.message });
		}
	}
};

const USER_FETCH = "USER_FETCH";
const USER_FETCH_ERROR = "USER_FETCH_ERROR";
export const fetchUser = () => async (dispatch, getState) => {
	try {
		const res = await axios.get(`${ process.env.GATSBY_API_BASE_URL }/api/users/me`, {
			headers: {
				"Authorization": `Bearer ${getState().user.token}`,
			},
		});
		dispatch({ type: USER_FETCH, data: res.data });
	} catch (err) {
		console.log(err.request);
		dispatch({ type: USER_FETCH_ERROR, error: JSON.parse(err.request.response) });
		dispatch(logoutUser());
		navigate("/");
	}
};

const USER_UPDATE = "USER_UPDATE";
export const updateUser = (firstName, lastName) => async (dispatch, getState) => {
	dispatch({ type: USER_UPDATE, firstName, lastName });
};

const USER_LOGOUT = "USER_LOGOUT";
export const logoutUser = () => async (dispatch, getState) => {
	dispatch({ type: USER_LOGOUT });
};

export default (state = initialState, action) =>
	produce(state, draft => {
		switch (action.type) {
			case USER_LOGIN:
				draft.token = action.data.access_token;
				draft.error.login = "";
				draft.error.fetch = "";
				break;
			case USER_LOGIN_ERROR:
				draft.error.login = action.error;
				break;

			case USER_FETCH:
				draft.user = action.data;
				draft.error.fetch = "";
				break;
			case USER_FETCH_ERROR:
				draft.error.fetch = action.error;
				break;

			case USER_UPDATE:
				draft.user.firstName = action.firstName;
				draft.user.lastName = action.lastName;
				break;

			case USER_LOGOUT:
				draft.token = "";
				draft.user = initialState.user;
				break;

			default:
				break;
		}
	});
