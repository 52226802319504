const path = require("path")

export function addFileSubfix(filename, subfix) {
    const extname = path.extname(filename)
    const basename = path.basename(filename, extname)
    return `${basename}${subfix}${extname}`
}

export function getOptionsFormData(options) {
    const formData = new FormData()
    formData.append("stripMetadata", options.stripMetadata)
    formData.append("lossless", options.lossless)
    formData.append("pOptLevel", options.pOptLevel)
    formData.append("pQuality", options.pQuality)
    formData.append("jQuality", options.jQuality)
    formData.append("jProgressive", options.jProgressive)
    formData.append("sRemoveScript", options.sRemoveScript)
    formData.append("gInterlaced", options.gInterlaced)
    formData.append("gOptLevel", options.gOptLevel)
    formData.append("gLossLevel", options.gLossLevel)
    return formData
}

export function renameFile(filepath, newname, index) {
    return `${newname}${index ? `-${index}` : ""}${path.extname(filepath)}`
}

export function getFileName(filepath) {
    const extname = path.extname(filepath)
    const basename = path.basename(filepath, extname)
    return `${basename}${extname}`
}

export function getFileMIME(filename) {
    switch (path.extname(filename)) {
        case ".png":
            return "image/png"

        case ".jpg":
        case ".jpeg":
            return "image/jpeg"

        case ".svg":
            return "image/svg+xml"

        case ".gif":
            return "image/gif"

        default:
            return ""
    }
}

export function getDownscaleMIME(filename) {
    switch (path.extname(filename)) {
        case ".PNG":
        case ".png":
            return "png"

        case ".jpg":
        case ".JPG":
        case ".jfif":
        case ".JFIF":
        case ".JPEG":
        case ".jpeg":
            return "jpeg"

        default:
            return null
    }
}

export function toHumanFileSize(size) {
    if (+size === 0) return "0 B"

    const i = Math.floor(Math.log(size) / Math.log(1024))
    return (
        (size / Math.pow(1024, i)).toFixed(2) * 1 +
        " " +
        ["B", "kB", "MB", "GB", "TB"][i]
    )
}

export function isAuthed(token) {
    return token && token.length > 0
}

export const getAxiosAuthHeaders = (token) => ({
    Authorization: `Bearer ${token}`,
})

export const getAxiosAuthConfig = (token) => ({
    headers: { Authorization: `Bearer ${token}` },
})
