// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/home/tinify/nanopng-web/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/home/tinify/nanopng-web/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-deleted-js": () => import("/home/tinify/nanopng-web/src/pages/account-deleted.js" /* webpackChunkName: "component---src-pages-account-deleted-js" */),
  "component---src-pages-confirm-index-js": () => import("/home/tinify/nanopng-web/src/pages/confirm/index.js" /* webpackChunkName: "component---src-pages-confirm-index-js" */),
  "component---src-pages-contact-js": () => import("/home/tinify/nanopng-web/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-dashboard-js": () => import("/home/tinify/nanopng-web/src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-delete-account-js": () => import("/home/tinify/nanopng-web/src/pages/delete-account.js" /* webpackChunkName: "component---src-pages-delete-account-js" */),
  "component---src-pages-faq-js": () => import("/home/tinify/nanopng-web/src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-features-js": () => import("/home/tinify/nanopng-web/src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-imprint-js": () => import("/home/tinify/nanopng-web/src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("/home/tinify/nanopng-web/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("/home/tinify/nanopng-web/src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-privacy-policy-js": () => import("/home/tinify/nanopng-web/src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-reset-password-js": () => import("/home/tinify/nanopng-web/src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-signup-success-js": () => import("/home/tinify/nanopng-web/src/pages/signup-success.js" /* webpackChunkName: "component---src-pages-signup-success-js" */),
  "component---src-pages-signup-js": () => import("/home/tinify/nanopng-web/src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-terms-of-service-js": () => import("/home/tinify/nanopng-web/src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */)
}

